header {
    color: white;
    padding: 5px; 
    /*background-image: url("network1.jpg");*/
    background-color: black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 20px;
}

h1{
    padding-left: 20px;
}


.nav-links {
    list-style: none;
}

.nav-links li{
    display: inline-block;
    padding: 0px 20px;
}

.nav-links li a{
    display: block;
    color: white;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
}

.nav-links li a:hover {
    background-color: rgb(85, 82, 82);
    border-radius: 20px;
  }