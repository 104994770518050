
.home {
    display: flex;
    border-top: 2px solid white;
    border-bottom: 2px solid white;
}
.left{
    padding-top: 20px;
    background-color: whitesmoke;
    width: 50%;
    float: left;
    display: flex;
    justify-content: center;
    text-align: center;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

.right{
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: rgb(26, 24, 24);
    width: 50%;
    float: left;
    display: flex;
    justify-content: center;
    text-align: center;
    color: white;
    padding-right: 20px;
}

.about-me {
    padding-right: 20px;
}
@media (max-width: 1000px){
    .home {
        display:table;
        padding: auto;
    }
    .left{
        padding-top: 20px;
        background-color: whitesmoke;
        width: 100%;
        display: flex;
        justify-content: center;
        text-align: center;
        border-radius: 20px;
    }
    
    .right{
        border-radius: 20px;
        background-color: rgb(26, 24, 24);
        padding-top: 20px;
        width: 100%;
        display: flex;
        justify-content: center;
        text-align: center;
        padding-right: 0px;
    }
    
}

img {
    border-radius: 50%;
    width: 50%;
}


li {
    list-style: none;
    padding-right: 20px;
}

.resume{
    border: 2px solid white;
    padding-right: 20px;
}
li a{
    display: block;
    color: white;
    text-align: center;
    padding: 14px 20px;
    text-decoration: none;
    border-radius: 20px;
}


li a:hover {
    background-color:rgb(85, 82, 82);
    border-radius: 20px;
  }

.paragraphs {
    text-align: left;
    padding-right: 20px;
}

.end{
    border-radius: 20px;
   /* background-image: url("network1.jpg");*/
    background-color: rgb(26, 24, 24);
    display: flex;
    margin-bottom: 0;
    justify-content: center;
    padding-right: 20px;
    color: white;
    text-align: center;
}